import styles from './styles.css'

function TableRow({start, event, theme, speakers}) {
    return (
        <tr>
            <td className='field start'>
                {start}
            </td>
            <td className='field'>
                {event}
            </td>
            <td className='field'>
                {theme}
            </td>
            <td className='field speaker-name'>
                {speakers.map((event, index) => (
                    <div className='speaker-name-container'>
                    <tr>{event}</tr>
                    </div>
                ))}
            </td>
        </tr>
    )
}

function ScheduleTable({obj}) {

    return (
        <div className='scheduleTableContainer'>
            <table>
                <thead className='headContainer'>
                    <tr className="labelsRow">
                        <td>
                            Início
                        </td>
                        <td>
                            Evento
                        </td>
                        <td>
                            Tema
                        </td>
                        <td>
                            Facilitadores
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {obj.data.map((event, index) => (
                        <>
                            <TableRow key={index} start={event.start} event={event.event} theme={event.theme} speakers={event.speakers}/>
                            {/* {console.log('speaker',event.speakers)} */}
                        </>
                    ))}
                    <tr className='table-details'>
                        <td colspan='4'>
                            {obj.details}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ScheduleTable