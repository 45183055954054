import React, { useEffect, useState } from 'react'
import styles from './styles.css'
import {schedules} from './schedule_data'
import ScheduleTable from './Table'

function BoardItems({obj, index, flip, setFlip}) {
    const [preFlip, setPreFlip] = useState("")

    const toggleFlip = (new_index) => {
        setTimeout(
            function() {
                if (new_index !== -1 && obj.theme) {
                    setFlip(index)
                } else {
                    setFlip(-1)
                }
            },
            150
        )
    }

    return (
        <>
        <div className='boarditems' onMouseEnter={() => toggleFlip(index)} onMouseLeave={()=>toggleFlip(-1)}>
            <div className={'boarditems-container' + ` ${flip === index ? 'pre-flip' : ''}`}>
                {flip !== index &&
                <div className='boarditems-intenarl-container'>
                    <h2>{obj.start}</h2>
                    <h3>{obj.event}</h3>
                    {obj.theme !== "" && <span>Saiba mais</span>}
                </div>
                }
                {flip === index && 
                <div className='boarditems-intenarl-container'>
                    <p>{obj.theme}</p>
                </div>
                } 
            </div>
        </div>
        </>
    )
}

function BoardSlider({objs, flip, setFlip}) {
    // A object must contain data and title
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);


    function switchTab(next) {
        if (next) {
            var shifted = objs.shift()
            objs.push(shifted)
        }
        else {
            var pop = objs.pop()
            objs.unshift(pop)
        }
        setFlip(-1)
        forceUpdate()
        // setArrowDir(arrowDir == 'right' ? 'left' : 'right')
    }
    

    return (
        <>
            <span onClick={() => switchTab(false)} className="material-symbols-outlined arrow">navigate_before</span>
            <div className='slider-board-items-container'>
                {objs.map((obj, index) => (
                    <BoardItems key={index} flip={flip} setFlip={setFlip} index={index} obj={obj}/>
                    ))}
            </div>
            <span onClick={() => switchTab(true)} className="material-symbols-outlined arrow">navigate_next</span>
        </>
    )
}

function Board({obj}) {
    const [flip, setFlip] = useState(-1)

    return (
        <div className='board'>
            <div className='board-title'>
                <h2>{obj.title}</h2>
            </div>
            <div className='board-items full-board'>
                {obj.data.map((_obj, index) => (
                    <BoardItems key={index} flip={flip} setFlip={setFlip} index={index} obj={_obj}/>
                ))}
            </div>
            <div className='slider-board-items reduced-board'>
                <BoardSlider objs={obj.data} flip={flip} setFlip={setFlip}/>
            </div>
        </div>
    )
}

// function useHover() {
//     const [hovering, setHovering] = useState(false)
//     const onHoverProps = {
//       onMouseEnter: () => setHovering(true),
//       onMouseLeave: () => setHovering(false),
//     }
//     return [hovering, onHoverProps]
//   }

function Schedule() {
    return (
        <div id='schedule' className='schedule'>
            <div className='scheduleItems'>
                <h1>Programação</h1>
                <div className='showBoard'>
                    <Board obj={schedules[0]}/>
                    <Board obj={schedules[1]}/>
                </div>
                <div className='showTable'>
                    <ScheduleTable obj={schedules[0]}/>
                    <ScheduleTable obj={schedules[1]}/>
                </div>
            </div>
        </div>
    )
}

export default Schedule