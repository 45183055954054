import { useEffect, useState } from "react";
import logo from "../../imgs/horizontal_render.svg";
import styles from './styles.css'
import styled from "styled-components";


function HackathonAlert() {
	const [showMenu, setShowMenu] = useState(false)
	const [expanding, setExpanding] = useState(false)

	const toggleMenu = () => {
		setTimeout(
            function() {
                setShowMenu(!showMenu)
            },
            300
        )
	}

	useEffect(() => {
		if (expanding){
			toggleMenu()
		}
	}, [expanding])

	useEffect(() => {
		setExpanding(false)
	}, [showMenu])

	// useEffect(() => {
    //     if (showMenu) {
    //         setExpanding(false)
    //     }
    // }, [showMenu])

	return (
		<div className={"hackheader " + `${expanding ? 'expanding' : ''}`}>
			<div className="hackmenu">
                <div className={"hacknavigators " + `${showMenu ? 'hamburger-show':''}`}>
                    <h3>Confira agora o regulamento do hackathon</h3>
					<button className={'hackregisterButton hackmenuButton ' + `${showMenu ? 'hamburger-button-show':''}`}><a href="/hackathon/DESAFIO_HACKATHON_NORDX.pdf" target="_blank" rel="noopener noreferrer">Regulamento hackathon</a></button>
                </div>
			</div>
		</div>
	);
}

export default HackathonAlert;
