import styles from './styles.css'

function Card({image, name, title, company}) {
	return (
        <div className='card'>
            <div className='card-icon'>
                <img className='card-image' src={image} alt='logo da instituição'/>
            </div>
            <div className='card-name'>
                {name}
            </div>
            <div className='card-title'>
                {title}
            </div>
            <div className='card-company'>
                <img src={company} alt='foto do colaborador'/>
            </div>
        </div>
	)
}

export default Card