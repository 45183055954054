import logo from './logo.svg';
import './App.css';
import MainScreen from './components/Home';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Speakers from './components/Speakers';
import Separator from './components/Separator';
import Schedule from './components/Schedule';
import Partners from './components/Partners';
import TheEvent from './components/TheEvent';
import Footer from './components/Footer';
import FAQ from './components/FAQ';
import ForWho from './components/ForWho';
import StayIn from './components/StayIn';
import CountdownTimer from './components/Countdown/countdownTimer';
import HackathonAlert from './components/HackathonAlert';
import Sponsors from './components/Sponsors';

function App() {
  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  // const THREE_DAYS_IN_MS = 3 * 1000;
  const TARGET_DATE_IN_MS = new Date(2024, 2, 26, 9).getTime();
  const NOW_IN_MS = new Date().getTime();

  // const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  const dateTimeAfterThreeDays = TARGET_DATE_IN_MS;
  console.log(new Date(2023, 1, 1));

  return (
    <div id='main' className="app">
      <div className="pageContainer">
        <Header />
        <HackathonAlert/>
        <MainScreen/>
        <AboutUs/>
        <TheEvent/>
        {/* <ForWho/> */}
        <Speakers/>
        <Schedule/>
        <CountdownTimer targetDate={dateTimeAfterThreeDays}/>
        <Sponsors/>
        <Partners/>
        {/* <FAQ/> */}
        <StayIn/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
