import styles from './styles.css'
import QRCode from '../QRCode'
import instaqr from '../../imgs/instagram-qrcode.jpg'
import symplaqr from '../../imgs/sympla-qrcode-logo.jpg'

function StayIn() {
    return (
        <div className='stayin'>
            <h1>Fique por dentro!</h1>
            <div className='stayin-container'>
                <QRCode file_path={instaqr} href={'https://www.instagram.com/nordxoficial/'}/>
                <QRCode file_path={symplaqr} href={"https://www.sympla.com.br/evento/nordx/2270050"}/>
            </div>
        </div>
    )
}

export default StayIn