import styles from './styles.css'
import { useState } from 'react'
import abria from '../../imgs/partners/abria.png'
import accg from '../../imgs/partners/accg.png'
import eij from '../../imgs/partners/eij.png'
import garden from '../../imgs/partners/garden.png'
import fdcangels from '../../imgs/partners/fdcangenls.png'
import gvangels from '../../imgs/partners/gvangels.png'
import ppy from '../../imgs/partners/ppy.png'
import simep from '../../imgs/partners/simep.png'
import speedy from '../../imgs/partners/speedy.png'
import v from '../../imgs/partners/v.png'
import inovabra from '../../imgs/partners/inovabra.png'
import banko from '../../imgs/partners/banko.jpg'
import advb from '../../imgs/partners/advb.png'
import rafaela_jales from '../../imgs/partners/rafaela_jales.png'
import gr from '../../imgs/partners/gr.png'
import iel from '../../imgs/partners/iel.jpg'
import oabcg from '../../imgs/partners/oabcg.jpg'
import tcep from '../../imgs/partners/tcep.jpg'
import senai_paraiba from '../../imgs/partners/senai_paraiba.jpg'
import paqtcpb from '../../imgs/partners/paqtcpb.jpg'
import cesapb from '../../imgs/partners/cesapb.jpg'
import dzb from '../../imgs/partners/dzbinvest.jpg'
import crecipb from '../../imgs/partners/crecipb.jpg'
import sadb_adv from '../../imgs/partners/sadb_adv.jpg'
import abh_pb from '../../imgs/partners/abh_pb.jpg'
import sesi from '../../imgs/partners/sesi_rec.jpg'
import senai from '../../imgs/partners/senai.jpg'
import aci from '../../imgs/partners/aci.jpg'
import ab2l from '../../imgs/partners/ab2l.jpg'
import anjos_brasil from '../../imgs/partners/anjos_brasil.jpg'
import secties from '../../imgs/partners/secties.jpg'
import gov_pb from '../../imgs/partners/gov_pb.jpg'
import finep from '../../imgs/partners/finep.jpg'
import sebrae from '../../imgs/partners/sebrae.jpg'
import unilever from '../../imgs/partners/unilever.jpg'

import ReCAPTCHA from 'react-google-recaptcha'

function PartnerForm ({setShowModal}) {
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [area, setArea] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const handleSubmit = (e) => {
        if (name === "" || email === "" || company === "" || phone === "") {
            e.preventDefault()
            alert("Por favor, preencha todos os campos obrigatórios (*)")
        }
    }

    return (
        <>
        <form className='modal-form' onSubmit={handleSubmit} autocomplete="false" role="form" method="post" action="https://nordx.net.br/mautic/form/submit?formId=1" id="mauticform_parceiros" data-mautic-form="parceiros" enctype="multipart/form-data">
        <button onClick={() => setShowModal(false)} value="" title='Voltar' class="mauticform-close-button">X</button>
        <h2>Torne-se um patrocinador!</h2>
        <div class="mauticform-error" id="mauticform_parceiros_error"></div>
        <div class="mauticform-message" id="mauticform_parceiros_message"></div>
        <div class="mauticform-innerform">
            
            <div class="mauticform-page-wrapper mauticform-page-1 form-items" data-mautic-form-page="1">

            <div id="mauticform_parceiros_nome_completo" data-validate="nome_completo" data-validation-type="text" class="mauticform-row mauticform-text mauticform-field-1 mauticform-required form-item">
                <label id="mauticform_label_parceiros_nome_completo" for="mauticform_input_parceiros_nome_completo" class="mauticform-label">Nome completo</label>
                <input id="mauticform_input_parceiros_nome_completo" name="mauticform[nome_completo]" value={name} onChange={(e) => setName(e.currentTarget.value)} class="mauticform-input" type="text"/>
                <span class="mauticform-errormsg" style={{display: "none"}}>Este campo é obrigatório</span>
            </div>

            <div id="mauticform_parceiros_numero_para_contato" data-validate="numero_para_contato" data-validation-type="tel" class="mauticform-row mauticform-tel mauticform-field-2 mauticform-required form-item">
                <label id="mauticform_label_parceiros_numero_para_contato" for="mauticform_input_parceiros_numero_para_contato" class="mauticform-label">Número para contato</label>
                <input id="mauticform_input_parceiros_numero_para_contato" name="mauticform[numero_para_contato]" value={phone} onChange={(e) => setPhone(e.currentTarget.value)} class="mauticform-input" type="tel"/>
                <span class="mauticform-errormsg" style={{display: "none"}}>Este campo é obrigatório</span>
            </div>

            <div id="mauticform_parceiros_email" data-validate="email" data-validation-type="text" class="mauticform-row mauticform-text mauticform-field-3 mauticform-required form-item">
                <label id="mauticform_label_parceiros_email" for="mauticform_input_parceiros_email" class="mauticform-label">Email</label>
                <input id="mauticform_input_parceiros_email" name="mauticform[email]" value={email} onChange={(e) => setEmail(e.currentTarget.value)} class="mauticform-input" type="text"/>
                <span class="mauticform-errormsg" style={{display: "none"}}>Este campo é obrigatório</span>
            </div>

            <div id="mauticform_parceiros_nome_da_empresa" data-validate="nome_da_empresa" data-validation-type="text" class="mauticform-row mauticform-text mauticform-field-4 mauticform-required form-item">
                <label id="mauticform_label_parceiros_nome_da_empresa" for="mauticform_input_parceiros_nome_da_empresa" class="mauticform-label">Nome da empresa</label>
                <input id="mauticform_input_parceiros_nome_da_empresa" name="mauticform[nome_da_empresa]" value={company} onChange={(e) => setCompany(e.currentTarget.value)} class="mauticform-input" type="text"/>
                <span class="mauticform-errormsg" style={{display: "none"}}>Este campo é obrigatório</span>
            </div>

            <div id="mauticform_parceiros_qual_a_area_de_atuacao_da" class="mauticform-row mauticform-text mauticform-field-5 form-item">
                <label id="mauticform_label_parceiros_qual_a_area_de_atuacao_da" for="mauticform_input_parceiros_qual_a_area_de_atuacao_da" class="mauticform-label">Área de atuação da empresa</label>
                <input id="mauticform_input_parceiros_qual_a_area_de_atuacao_da" name="mauticform[qual_a_area_de_atuacao_da]" value={area} onChange={(e) => setArea(e.currentTarget.value)} class="mauticform-input" type="text"/>
                <span class="mauticform-errormsg" style={{display: "none"}}></span>
            </div>

            <div id="mauticform_parceiros_nos_conte_sobre_sua_empre" class="mauticform-row mauticform-text mauticform-field-6 form-item">
                <label id="mauticform_label_parceiros_nos_conte_sobre_sua_empre" for="mauticform_input_parceiros_nos_conte_sobre_sua_empre" class="mauticform-label">Conte-nos sobre sua empresa</label>
                <textarea id="mauticform_input_parceiros_nos_conte_sobre_sua_empre" name="mauticform[nos_conte_sobre_sua_empre]" class="mauticform-textarea"></textarea>
                <span class="mauticform-errormsg" style={{display: "none"}}></span>
            </div>

            <div id="mauticform_parceiros_submit" class="mauticform-row mauticform-button-wrapper mauticform-field-7 form-item">
                <button type="submit" name="mauticform[submit]" id="mauticform_input_parceiros_submit" value="" class="mauticform-button btn btn-default">Enviar</button>
            </div>
            </div>
        </div>

        <input type="hidden" name="mauticform[formId]" id="mauticform_parceiros_id" value="1"/>
        <input type="hidden" name="mauticform[return]" id="mauticform_parceiros_return" value=""/>
        <input type="hidden" name="mauticform[formName]" id="mauticform_parceiros_name" value="parceiros"/>

        </form>
    

        </>
    )
}

function PartnerFormModal({setShowModal}) {
    const toggleModal = () => {
       window.onclick = function(event) {
            if (event.target.className === 'partner-modal') {
                setShowModal(false);
            }
        }
    }

    return (
        <div onClick={toggleModal} className='partner-modal'>
            <div className='partner-modal-container'>
                <PartnerForm setShowModal={setShowModal}/>
            </div>
        </div>
    )
}

function PartnerCard({logo}) {
    return (
        <div className='partnerCard'>
            <img src={logo}>
            </img>
        </div>
    )
}

function Partners(){
    const [showModal, setShowModal] = useState(false)

    return (
        <div id="partners-anchor" className='partners'>
            {showModal && <PartnerFormModal setShowModal={setShowModal}/>}
            <div className='partnersHeader'>
                <h1>Parceiros e Patrocinadores</h1>
            </div>
            <div className='partnersContainer'>
                <PartnerCard logo={abria}/>
                <PartnerCard logo={accg}/>
                <PartnerCard logo={garden}/>
                <PartnerCard logo={eij}/>
                <PartnerCard logo={fdcangels}/>
                <PartnerCard logo={gvangels}/>
                <PartnerCard logo={ppy}/>
                <PartnerCard logo={simep}/>
                <PartnerCard logo={speedy}/>
                <PartnerCard logo={v}/>
                <PartnerCard logo={inovabra}/>
                <PartnerCard logo={banko}/>
                {/* <PartnerCard logo={advb}/> */}
                <PartnerCard logo={rafaela_jales}/>
                <PartnerCard logo={gr}/>
                <PartnerCard logo={iel}/>
                <PartnerCard logo={oabcg}/>
                <PartnerCard logo={senai_paraiba}/>
                <PartnerCard logo={tcep}/>
                <PartnerCard logo={paqtcpb}/>
                <PartnerCard logo={cesapb}/>
                <PartnerCard logo={dzb}/>
                <PartnerCard logo={crecipb}/>
                <PartnerCard logo={senai}/>
                <PartnerCard logo={sesi}/>
                <PartnerCard logo={abh_pb}/>
                <PartnerCard logo={sadb_adv}/>
                <PartnerCard logo={aci}/>
                <PartnerCard logo={ab2l}/>
                <PartnerCard logo={anjos_brasil}/>
                <PartnerCard logo={secties}/>
                <PartnerCard logo={gov_pb}/>
                <PartnerCard logo={finep}/>
                <PartnerCard logo={sebrae}/>
                <PartnerCard logo={unilever}/>
            </div>
                <div className='partners-button-container'>
                    <button onClick={() => setShowModal(true)} className='partners-button'>
                        Torne-se um patrocinador
                    </button>
                </div>
        </div>
    )
}

export default Partners