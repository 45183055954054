import React from 'react';
import { useCountdown } from './hooks/useCountdown';
import DateTimeDisplay from './datetimeDisplay';
import styles from './styles.css'

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Começou!</span>
      <p>mas corre que ainda dá tempo</p>
      <button className={'registerButton menuButton '}><a href="https://www.sympla.com.br/evento/nordx/2270050" target="_blank" rel="noopener noreferrer">Quero fazer parte!</a></button>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <a
        className="countdown-link"
      >
        <DateTimeDisplay value={days} type={'Dias'} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={'Horas'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Minutos'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Segundos'} isDanger={false} />
      </a>
      <button className={'registerButton menuButton '}><a href="https://www.sympla.com.br/evento/nordx/2270050" target="_blank" rel="noopener noreferrer">Quero fazer parte!</a></button>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  console.log(days, hours, minutes)
  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
