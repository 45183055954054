import styles from './styles.css'
import social from '../../imgs/white_bwink_msc_07_single_01.webp'
function EventCard({position, text}) {
    return (
        <div className={'eventcard' + ` ${position}`}>
            <div className='eventcard-container'>
                <h2>{text}</h2>
            </div>
        </div>
    )
}

function EventLastCard() {
    return (
        <div className='eventlastcard'>
            <div className='eventlastcard-container'>
                <h2>Para você</h2>
            </div>
        </div>
    )
}

function TheEvent() {
    return (
        <div id='event'>
            <div className='event'>
                <div className='event-container'>
                    <h1>Conectando empreendedores e investidores</h1>
                    <p>
                    Faça parte do evento que está impulsionando o ecossistema empresarial do Nordeste. Um evento que combina networking, captação de investimentos, construção de visibilidade e premiação para desbravar novos horizontes e impulsionar o cenário econômico da região
                    </p>
                </div>
                <div className='event-container'>
                    <div className='event-image'>
                        <img src={social}/>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default TheEvent