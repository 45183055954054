import styles from './styles.css'

function QRCode({file_path, href}) {
    return (
        <div className='qrcode'>
            <div className='qrcode-container'>
                <a href={href} target="_blank" rel="noopener noreferrer">
                    <img src={file_path} alt='qrcode-instagram'/>
                </a>
            </div>
        </div>
    )
}

export default QRCode