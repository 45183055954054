import { useEffect, useState } from "react";
import logo from "../../imgs/horizontal_render.svg";
import styles from './styles.css'
import styled from "styled-components";


function Header() {
	const [showMenu, setShowMenu] = useState(false)
	const [expanding, setExpanding] = useState(false)

	const toggleMenu = () => {
		setTimeout(
            function() {
                setShowMenu(!showMenu)
            },
            300
        )
	}

	useEffect(() => {
		if (expanding){
			toggleMenu()
		}
	}, [expanding])

	useEffect(() => {
		setExpanding(false)
	}, [showMenu])

	// useEffect(() => {
    //     if (showMenu) {
    //         setExpanding(false)
    //     }
    // }, [showMenu])

	return (
		<div className={"header " + `${expanding ? 'expanding' : ''}`}>
			<div className="menu">
				<a href="#main"><img alt="logo schiefler academy" src={logo}></img></a>
                <div className={"navigators " + `${showMenu ? 'hamburger-show':''}`}>
                    <a href="#main">Início</a>
                    <a href="#about">Sobre</a>
                    <a href="#speakers">Palestrantes</a>
					<a href="#schedule">Programação</a>
                    {/* <a href="#faq">FAQ</a> */}
					<button className={'registerButton menuButton ' + `${showMenu ? 'hamburger-button-show':''}`}><a href="https://www.sympla.com.br/evento/nordx/2270050" target="_blank" rel="noopener noreferrer">Quero fazer parte!</a></button>
                </div>
				<div className="hamburger" onClick={() => setExpanding(true)}>
					<a href="#menu"><span className="material-symbols-outlined">
					menu
					</span></a>
				</div>
			</div>
		</div>
	);
}

export default Header;
