import styles from './styles.css'
import logo from '../../imgs/nordx_render.png'
import social from '../../imgs/red_engine.webp'

function AboutUs() {
	return (
        <div id='about' className='about'>
            <div className='about-container'>
                <div className='about-items'>
                    <div className='about-image'>
                        <img src={social}/>
                    </div>
                </div>
                <div className='about-items'>
                    <div className="about-text">
                        <h1>O Nordeste em novos patamares</h1>
                        {/* <p>A crença profunda de que a verdadeira inovação surge da diversidade extraordinária que é o Brasil. Estamos impulsionados pela paixão de destacar e celebrar o potencial único desta terra.</p> */}
                        <p>O NordX nasce da busca por descentralizar o acesso a oportunidades de empreender. Acreditamos e apostamos que a inovação está distribuída na diversidade de todo o nosso país, e não concentrada em alguns lugares.</p>
                        <p>Por isso, oferecemos um evento no Nordeste para o Nordeste – contando com investidores e especialistas de todo o Brasil, experiencie o estado da arte da inovação, levando investimento e catalisando a disrupção.</p>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default AboutUs