import styles from '../Partners/styles.css'
import { useState } from 'react'
import gov_caixa from '../../imgs/partners/gov_caixa.jpg'
import v from '../../imgs/partners/vinci.svg'
import unilever from '../../imgs/partners/garagem_unilever.png'
import unilever_crop from '../../imgs/partners/unilever_sponsor_crop.jpg'

import ReCAPTCHA from 'react-google-recaptcha'

function PartnerCard2({logo, width=100}) {
    return (
        <div className='partnerCard2'>
            <img src={logo} width={width}>
            </img>
        </div>
    )
}

function SponsorCard({logo}) {
    return (
        <div className='sponsorCard'>
            <img src={logo}/>
        </div>
    )
}

function Sponsors(){
    const [showModal, setShowModal] = useState(false)

    return (
        <div id="partners-anchor" className='partners'>
            <div className='partnersHeader'>
                <h1>Patrocínio</h1>
            </div>
            <div className='sponsorsContainer'>
                <PartnerCard2 logo={v} width={"140px"}/>
                <PartnerCard2 logo={unilever} width={"100px"}/>
                <SponsorCard logo={gov_caixa}/>
            </div>
        </div>
    )
}

export default Sponsors