export const schedules = [
    {
        "title":"26 de março - terça-feira",
        "data":
        [   
            {
                'start': '09:00',
                'event': 'Abertura dos portões e check-in',
                'theme': 'Visita aos stands e networking',
                'speakers':[]
            },
            {
                'start': '10:00',
                'event': 'Palestra inaugural & Mesa de abertura',
                'theme': 'Desafios e oportunidades para startups no Nordeste',
                'speakers':[
                    'Tânia Lustosa de Oliveira',
                    'Philipe Moura',
                    'Claudio Furtado',
                    'Laryssa Almeida',
                    'Marcos Carvalho',
                    'Rafaelly Fortunato',
                ]
            },
            {
                'start': '11:30',
                'event': 'Kickoff do hackthon',
                'theme': 'Transformando resíduos em recursos',
                'speakers': [
                    'Fabiana Hiluey',
                    'Ivana Sena',
                    'Carlos Rueda'
                ]
            },
            {
                'start': '12:00',
                'event': 'Almoço & Visita aos stands e networking',
                'theme': '',
                'speakers':[]
            },
            {
                'start': '13:50',
                'event': 'Ginástica laboral',
                'theme': '',
                'speakers':[
                        'Fabiana Hiluey',
                        'Ivana Sena',
                        'Carlos Rueda'
                    ]
            },
            {
                'start': '14:00',
                'event': 'Palestra',
                'theme': 'Programas da FINEP de financiamento à inovação',
                'speakers':['Felipe Gelelete']
            },
            {
                'start': '14:30',
                'event': 'Mesa redonda',
                'theme': 'Fundraising para startups',
                'speakers':[
                    'Philipe Moura',
                    'Wlado Teixeira',
                    'Fabiano Nagamatsu',
                    'João Costa',
                    'Danilo Nascimento'
                ]
            },
            {
                'start': '15:30',
                'event': 'Coffee Break & Visita aos stands e networking',
                'theme': '',
                'speakers':[]
            },
            {
                'start': '16:00',
                'event': 'Mesa redonda',
                'theme': 'Desafios jurídicos para founders e startups',
                'speakers':[
                    `Marina Gondin Ramos`,
                    `Melissa Pulice`,
                    `Gustavo Rabay`,
                    'José Vitor Lopes'
                ]
            },
            {
                'start': '17:00',
                'event': 'Palestra',
                'theme': 'Vendas em startups: por onde começar',
                'speakers':[
                    'Veronica Dantas',
                    'Fabio Farias',
                    'Hugo Melo'
                ]
            },
            {
                'start': '18:00',
                'event': 'Networking',
                'theme': '',
                'speakers':[]
            },
            {
                'start': '19:00',
                'event': 'Pitch night*',
                'theme': '6º Pitch Night FDC Angels',
                'speakers':['FDC Angels']
            }
        ],
        'details':'* Disponível apenas para os ingressos “Startup” e “Investidor”'
    },
    {
        "title":"27 de março - quarta-feira",
        "data":[
            {
                'start': '09:00',
                'event': 'Abertura dos portões e check-in',
                'theme': 'Visita aos stands e networking',
                'speakers':[]
            },
            {
                'start': '10:00',
                'event': 'Palestra e mesa redonda',
                'theme': 'Da pesquisa à inovação: transformando ideias em startups',
                'speakers':['Daniel Moura', 'Morganna Tito', 'Bruna Silva']
            },
            {
                'start': '11:00',
                'event': 'Palestra e mesa redonda',
                'theme': 'A jornada do founder como jornada do herói',
                'speakers':['Guilherme Berbert', 'Sarah Lucena', 'Fred Oliveira']
            },
            {
                'start': '12:00',
                'event': 'Almoço & Visita aos stands e networking',
                'theme': '',
                'speakers':[]
            },
            {
                'start': '13:50',
                'event': 'Ginástica laboral',
                'theme': '',
                'speakers':[
                    'Fabiana Hiluey',
                    'Ivana Sena',
                    'Carlos Rueda'
                ]
            },
            {
                'start': '14:00',
                'event': 'Palestra e mesa redonda',
                'theme': 'Startups e investimento de impacto',
                'speakers':['Eva Lazarin', 'Leticia Nunes Bezerra', 'Ramon Jung']
            },
            {
                'start': '15:00',
                'event': 'Palestra e mesa redonda',
                'theme': 'Inteligência artificial na transformação do Setor Público',
                'speakers':[
                    'José Marcos Carvalho Araújo',
                    'Luzemar Martins',
                    'Daniel Bichuetti',
                    'David Mendes'
                ]
            },
            {
                'start': '16:00',
                'event': 'Visita aos stands e networking',
                'theme': '',
                'speakers': []
            },
            {
                'start': '16:00',
                'event': 'Café com anjo*',
                'theme': 'Matchmaking',
                'speakers':['Vinci Ventures']
            },
            {
                'start': '17:00',
                'event': 'Apresentações do hackathon',
                'theme': '',
                'speakers':[
                    'Fabiana Hiluey',
                    'Ivana Sena',
                    'Carlos Rueda'
                ]
            },
            {
                'start': '18:00',
                'event': 'Encerramento e resultado do hackathon',
                'theme': '',
                'speakers':[
                    'Philipe Moura',
                    'Claudio Furtado'
                ]
            }
        ],
        'details':'* Disponível apenas para os ingressos “Startup” e “Investidor”'
    }
]