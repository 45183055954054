import styles from './styles.css'
import logo from '../../imgs/nordx_render.png'

function MainScreen() {
	return (
        <div className='container_pattern'>
            <div className="main">
                <div className="texto-1">
                    <img src={logo}></img>
                    <h1>empreendedorismo<br/>& inovação</h1>
                </div>
                <h2>Um evento no Nordeste para o Nordeste.</h2>
                <h2>Campina Grande – PB, 26 e 27 de março de 2024</h2>
                <button className='registerButton'><a href="https://www.sympla.com.br/evento/nordx/2270050" target="_blank" rel="noopener noreferrer">Quero fazer parte!</a></button>
            </div>
        </div>
	)
}

export default MainScreen