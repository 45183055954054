import styles from './styles.css'
import logo from '../../imgs/horizontal_render.svg'
import { FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import PoweredBy from '../PoweredBy';
import QRCode from '../QRCode';
import instaqr from '../../imgs/instagram-qrcode.jpg'
import symplaqr from '../../imgs/sympla-qrcode-logo.jpg'
import GenericModal from '../Modal';

function Footer() {
    return (
        <footer className='footer'>
            {/* <GenericModal file_path={instaqr}/> */}
            <div className='footer-container'>
                <div className='footer-items left-item'>
                    <a href="#main"><img src={logo}/></a>
                </div>
                <div className='footer-items right-item'>
                    <a href='https://www.linkedin.com/company/nordxoficial/' target="_blank" rel="noopener noreferrer"><FaLinkedin/></a>
                    <a href='https://www.instagram.com/nordxoficial/' target="_blank" rel="noopener noreferrer"><FaInstagram/></a>
                    <a href='https://www.facebook.com/nordxoficial' target="_blank" rel="noopener noreferrer"><FaFacebook/></a>
                </div>
            </div>
            {/* <div className='footer-container'>
                <div className='second-container'>
                    disclaimer
                </div>
            </div> */}
            <div className='footer-separator'/>
        </footer>
    )
}

export default Footer