import styles from './styles.css'
import Card from '../Cards'

import wlado from '../../imgs/speakers/wlado_teixeira.png'
import gvangel from '../../imgs/companies/company_gvangel.png'

import valter from '../../imgs/speakers/valter_wolf.png'
import abria from '../../imgs/companies/abria.png'

import eva_m from '../../imgs/speakers/eva_m_lazarin.png'
import benkyou from '../../imgs/companies/benkyou.png'

import david from '../../imgs/speakers/david_mendes.png'
import agenzia from '../../imgs/companies/agenzia.png'

import jose_vito from '../../imgs/speakers/jose_vitor_lopes.png'
import dzb from '../../imgs/companies/dzb.png'

import phil from '../../imgs/speakers/phil.png'
import v from '../../imgs/companies/company_v.png'

import marina from '../../imgs/speakers/marina.png'
import gr from '../../imgs/companies/gr.png'

import sarah from '../../imgs/speakers/sarah_lucena.png'
import f from '../../imgs/companies/f.png'

import fred from '../../imgs/speakers/fred_oliveira.png'
import sigalei from '../../imgs/companies/sigalei.png'

import morganna from '../../imgs/speakers/morganna.png'
import inovabra from '../../imgs/companies/inovabra.png'

import andre_menezes from '../../imgs/speakers/andre_menezes.png'
import banko from '../../imgs/companies/banko.png'

import leticia_nunes from '../../imgs/speakers/leticia_nunes.jpg'
import sdw from '../../imgs/companies/sdw.png'

import daniel_bichuetti from '../../imgs/speakers/daniel_bichuetti.jpg'
import intelijus from '../../imgs/companies/intelijus.jpg'

import danilo_nascimento from '../../imgs/speakers/danilo_nascimento.jpg'
import fdc from '../../imgs/companies/fdc.jpg'

import gustavo_rabay from '../../imgs/speakers/gustavo_rabay.jpg'
import rpcl from '../../imgs/companies/rpcl.jpg'

import joao_costa from '../../imgs/speakers/joao_costa.jpg'
import melissa_pulice from '../../imgs/speakers/melissa_pulice.jpg'
import zarv from '../../imgs/companies/zarv.webp'

import fabiano_nagamatsu from '../../imgs/speakers/fabiano_nagamatsu.jpg'
import osten_moove from '../../imgs/companies/osten_moove_logo.jpeg'

import felipe_gelelete from '../../imgs/speakers/felipe_gelelete.jpg'
import finep from '../../imgs/companies/finep.jpg'

import fabio_farias from '../../imgs/speakers/fabio_farias_crop.jpg'
import love_gifts from '../../imgs/companies/love_gifts_logo.jpeg'

function Speakers() {
    const speakers_data = [
        {
            'photo': wlado,
            'name': 'Wlado Teixeira',
            'title': 'Diretor Executivo',
            'company': gvangel
        },
        {
            'photo': valter,
            'name': 'Valter Wolf',
            'title': 'Presidente',
            'company': abria
        },
        {
            'photo': eva_m,
            'name': 'Eva M. Lazarin',
            'title': 'Founder & CEO',
            'company': benkyou
        },
        {
            'photo': david,
            'name': 'David Mendes',
            'title': 'Founder & CEO',
            'company': agenzia
        },
        {
            'photo': jose_vito,
            'name': 'José Vitor Lopes',
            'title': 'Founder',
            'company': dzb
        },
        {
            'photo': phil,
            'name': 'Philipe Moura',
            'title': 'Founder & CEO',
            'company': v
        },
        {
            'photo': marina,
            'name': 'Marina Gondin',
            'title': 'Founder Partner',
            'company': gr
        },
        {
            'photo': sarah,
            'name': 'Sarah Lucena',
            'title': 'Founder & CEO',
            'company': f
        },
        {
            'photo': fred,
            'name': 'Fred Oliveira',
            'title': 'Founder & CRO',
            'company': sigalei
        },
        {
            'photo': morganna,
            'name': 'Morganna Tito',
            'title': 'Community Manager',
            'company': inovabra
        },
        {
            'photo': leticia_nunes,
            'name': 'Leticia Nunes Bezerra',
            'title': 'CCO',
            'company': sdw
        },
        {
            'photo': daniel_bichuetti,
            'name': 'Daniel Bichuetti',
            'title': 'Co-founder & CTO',
            'company': intelijus
        },
        {
            'photo': danilo_nascimento,
            'name': 'Danilo Nascimento',
            'title': 'Prof. Convidado e Co-fundador e Diretor de Inovação',
            'company': fdc
        },
        {
            'photo': gustavo_rabay,
            'name': 'Gustavo Rabay',
            'title': 'Sócio',
            'company': rpcl
        },
        {
            'photo': joao_costa,
            'name': 'João Costa',
            'title': 'Founder & CEO',
            'company': zarv
        },
        {
            'photo': fabiano_nagamatsu,
            'name': 'Fabiano Nagamatsu',
            'title': 'CEO',
            'company': osten_moove
        },
        {
            'photo': melissa_pulice,
            'name': 'Melissa Pulice',
            'title': 'Head of Legal',
            'company': zarv
        },
        {
            'photo': felipe_gelelete,
            'name': 'Felipe Gelelete',
            'title': 'Analista',
            'company': finep
        },
        {
            'photo': fabio_farias,
            'name': 'Fábio Farias',
            'title': 'Founder & CEO',
            'company': love_gifts
        }
    ]

	return (
        <div id='speakers' className='speakers'>
            <div className='speakers-container'>
                <div className='speakers-intro'>
                    <h1>Presenças confirmadas</h1>
                </div>
                <div className="elements">
                    {speakers_data.map((person, index) => (
                        <Card key={index} image={person.photo} name={person.name} title={person.title} company={person.company}/>
                        ))}
                </div>
            </div>
        </div>
	)
}

export default Speakers